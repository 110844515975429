import React from 'react';

const project= () => {
  return (
    <section id="project">
      {/* console.log('hiii') */}
      <p className="section__text__p1">Browse My Recent</p>
      <h1 className="title">Projects</h1>

      <h2 style={{textAlign: 'center'}}>Coming Soon</h2>

      {/* <div className="experience-details-container">
        <div className="about-containers">
          <div className="details-container color-container">
            <div className="article-container">
              <img src="./assets/project-1.png" alt="Project 1" className="project-img" />
            </div>
            <h2 className="experience-sub-title project-title">Project One</h2>
            <div className="btn-container">
              <button className="btn btn-color-2 project-btn" onClick={() => window.location.href='https://github.com'}>Github</button>
              <button className="btn btn-color-2 project-btn" onClick={() => window.location.href='https://github.com'}>Live Demo</button>
            </div>
          </div>
          <div className="details-container color-container">
            <div className="article-container">
              <img src="./assets/project-2.png" alt="Project 2" className="project-img" />
            </div>
            <h2 className="experience-sub-title project-title">Project Two</h2>
            <div className="btn-container">
              <button className="btn btn-color-2 project-btn" onClick={() => window.location.href='https://github.com'}>Github</button>
              <button className="btn btn-color-2 project-btn" onClick={() => window.location.href='https://github.com'}>Live Demo</button>
            </div>
          </div>
          <div className="details-container color-container">
            <div className="article-container">
              <img src="./assets/project-3.png" alt="Project 3" className="project-img" />
            </div>
            <h2 className="experience-sub-title project-title">Project Three</h2>
            <div className="btn-container">
              <button className="btn btn-color-2 project-btn" onClick={() => window.location.href='https://github.com'}>Github</button>
              <button className="btn btn-color-2 project-btn" onClick={() => window.location.href='https://github.com'}>Live Demo</button>
            </div>
          </div>
        </div>
      </div> */}
      {/* todo */}
      {/* <img src="./assets/arrow.png" alt="Arrow icon" className="icon arrow" onClick={() => window.location.href='/#contact'} /> */}
    </section>
  );
};

export default project
