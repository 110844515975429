import HeroSection from "../HeroSection";
import Project from "../Project";

export default function Home() {
  return (
    <>
      <HeroSection />
      <Project />
    </>
  );
}
